<template>
  <v-dialog
    v-model="dialogRecibos"
    @click:outside="$emit('update:dialogRecibos', false)"
    @keydown.esc="$emit('update:dialogRecibos', false)"
    max-width="99%"
    scrollable
  >
    <v-card>
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.recibo", 2) }}

        <v-spacer></v-spacer>

        <v-btn icon @click="$emit('update:dialogRecibos', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card outlined class="ma-2" style="overflow-y: auto">
        <v-card-title class="text-body-1 px-3 py-2 lightgray">
          <v-text-field
            style="max-width: 400px"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$tc('global.buscar')"
            placeholder="pelo responsavel do pagamento"
            outlined
            solo
            flat
            dense
            hide-details
            append-outer-icon="mdi-cached"
            @click:append-outer="clearSearch"
          ></v-text-field>
          <v-switch
            v-model="mostrarCancelados"
            class="ml-4 mt-0 pt-0"
            label="Mostrar cancelados"
            hide-details
          >
          </v-switch>
          <v-spacer></v-spacer>
          <v-btn small color="button_1 white--text" @click="addReciboManual">
            {{ $tc("global.add") }}
            <v-icon right> mdi-plus </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-data-table
            :headers="headers"
            :items="recibos"
            :options.sync="options"
            :loading="loading"
            :server-items-length="totalRecibos"
            item-key="id"
            class="data-tables data-tables__row-click"
            @click:row="openReciboPdf"
          >
            <template v-slot:item.data_recibo="{ item }">
              {{ item.data_recibo | dateFormat("dd/MM/yyyy") }}
            </template>
            <template v-slot:item.valor_recibo="{ item }">
              <span v-if="item.moeda_sigla === 'G$'">
                {{ item.valor_recibo | guarani }}
              </span>
              <span v-else>{{ item.valor_recibo | currency }} </span>
            </template>

            <template v-slot:item.concepto_descricao="{ item }">
              <v-tooltip bottom color="darkblue">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    {{ item.concepto_descricao | excerpt(30) }}
                  </div>
                </template>
                <span>{{ item.concepto_descricao }}</span>
              </v-tooltip>
            </template>

            <template v-slot:item.quem_pagou="{ item }">
              <v-tooltip bottom color="darkblue">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    {{ item.quem_pagou | excerpt(30) }}
                  </div>
                </template>
                <span>{{ item.quem_pagou }}</span>
              </v-tooltip>
            </template>

            <template v-slot:item.actions="{ item }">
              <div v-if="item.origem === 'VIA-MANUAL'">
                <v-btn
                  icon
                  small
                  @click.stop="
                    item.origem === 'VIA-MANUAL' ? editarRecibo(item) : ''
                  "
                >
                  <v-icon v-if="item.origem === 'VIA-MANUAL'"
                    >mdi-pencil-outline</v-icon
                  >
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="RECIBO"
    />

    <DialogReciboManual
      v-if="dialogReciboManual"
      :dialogReciboManual.sync="dialogReciboManual"
      :edit.sync="edit"
      :item="recibo"
      @fetchAll="getRecibos"
      origem="VIA-MANUAL"
    />
  </v-dialog>
</template>

<script>
const generateRecibo = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboPdf.js"
  );
const generateReciboFiscal = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboFiscalPdf.js"
  );
const generateReciboCredito = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboAntecipoPdf.js"
  );
const generateTransferenciaCaixa = () =>
  import(
    /* webpackChunkName: "transferenciaReciboPdf" */ "@/views/dashboard/caixas-pdv/reports/pdvTransferenciaPdf.js"
  );
import { all as fetchAllRecibo } from "@/api/recibos/recibos.js";
export default {
  name: "Recibos",

  components: {
    DialogReciboManual: () =>
      import("@/components/recibo/components/DialogReciboManual.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  props: {
    dialogRecibos: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      mostrarCancelados: false,
      loading: true,
      dialogReciboManual: false,
      recibos: [],
      search: "",
      options: {
        sortBy: ["data_recibo"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 10,
      },
      totalRecibos: 0,
      debounce: null,
      edit: false,
      recibo: {},
      pdfDialog: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.numero"),
          value: "numero_recibo",
          divider: true,
        },
        {
          text: this.$tc("global.data"),
          value: "data_recibo",
          divider: true,
        },
        {
          text: this.$tc("global.emissor"),
          value: "usuario_nome",
          divider: true,
        },
        {
          text: this.$tc("global.origem"),
          value: "origem",
          divider: true,
        },

        {
          text: this.$tc("global.descricao"),
          value: "concepto_descricao",
          divider: true,
        },
        {
          text:
            this.$tc("global.responsavel") + " " + this.$tc("global.pagamento"),
          value: "quem_pagou",
          divider: true,
        },

        {
          text:
            this.$tc("global.responsavel") +
            " " +
            this.$tc("global.assinatura"),
          value: "quem_assina",
          divider: true,
        },

        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          align: "center",
          width: "100px",
          divider: true,
        },
        {
          text: this.$tc("global.valor"),
          align: "right",
          value: "valor_recibo",
          divider: true,
        },
        {
          text: "",
          sortable: false,
          align: "right",
          value: "actions",
        },
      ];
    },
  },

  watch: {
    search() {
      if (!this.search || this.loading) return;
      this.buscaRecibo();
    },
    options: {
      handler() {
        this.buscaRecibo();
      },
      deep: true,
    },
    mostrarCancelados() {
      this.buscaRecibo();
    },
  },

  methods: {
    editarRecibo(recibo) {
      this.edit = true;
      this.recibo = { ...recibo };
      this.dialogReciboManual = true;
    },

    async openReciboPdf(recibo) {
      this.loading = true;
      if (recibo.tipo == "RECEBIMENTO") {
        await generateReciboFiscal().then((module) => {
          let generate = module.generateReciboFiscal;
          generate(recibo.id)
            .then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            })
            .finally(() => {
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        });
      } else if (recibo.tipo == "CREDITO") {
        await generateReciboCredito().then((module) => {
          let generate = module.generateRecibo;
          generate(recibo.id)
            .then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            })
            .finally(() => {
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        });
      } else if (recibo.tipo == "TRANSFERENCIA") {
        await generateTransferenciaCaixa().then((module) => {
          let generate = module.generateTransferencia;
          generate(recibo.id)
            .then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            })
            .finally(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        });
      } else {
        await generateRecibo().then((module) => {
          let generate = module.generateRecibo;
          generate(recibo.id)
            .then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            })
            .finally(() => {
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        });
      }
    },

    addReciboManual() {
      this.dialogReciboManual = true;
    },

    async getRecibos(filtro) {
      try {
        this.loading = true;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const response = await fetchAllRecibo(
          `?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}` +
            (filtro ? filtro : "")
        );

        this.recibos = response.data;
        this.totalRecibos = response.total;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    buscaRecibo() {
      let filtro = "";
      if (this.search) {
        filtro += `&search=${this.search}`;
      }
      if (this.mostrarCancelados) {
        filtro += `&mostrarCancelados=${this.mostrarCancelados}`;
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.getRecibos(filtro);
      }, 500);
    },

    clearSearch() {
      this.search = "";
      this.getRecibos();
    },
  },

  async mounted() {
    // await this.getRecibos();
  },
};
</script>

<style></style>
